import { between, up } from "styled-breakpoints"
import styled from "styled-components"
import mdx from "../../components/mdx"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"
import { Typography } from "../../components/system/typography"

export const Layout = styled(FlexRow)`
  background: ${({ theme }) => theme.colors.blue};
  flex-wrap: wrap;
  padding: 0 1.8rem;

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("lg")} {
    display: initial;
    flex: 3;
  }

  ${up("xl")} {
    flex: 5;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("lg")} {
    padding-left: 0;
    flex: 6;
  }

  ${up("xl")} {
    flex: 7;
  }
`

export const TableOfContents = styled.ol`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${up("lg")} {
    margin-top: 8rem;
    margin-bottom: 12rem;
  }
`
export const ChapterLink = styled(mdx.Link)`
  font: inherit;
  margin: 0;
`

export const ChapterListElement = styled(Typography).attrs({
  as: "li",
  variant: "body",
  typo: "azo",
})`
  margin-bottom: 2.5rem;

  &[data-depth="4"] + &[data-depth="3"] {
    margin-top: 4.5rem;
  }
`
