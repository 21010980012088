import { graphql, PageProps } from "gatsby"
import React, { useMemo } from "react"
import { ThemeProvider } from "styled-components"
import AdvancedHeader from "../components/header/advanced"
import ListElement from "../components/list-element"
import PageHeading from "../components/page-heading"
import Seo from "../components/seo"
import GlobalStyles from "../styles/globals"
import * as Styled from "../styles/pages/toc"
import { blueTheme } from "../styles/themes"
import Divider from "../components/divider"
import ScrollUpCTA from "../components/ScrollUpCTA"
import { getChapterNodeTitle } from "../util/getChapterNodeTitle"
import { filterChapters } from "../util/filterChapters"
import { sortChapters } from "../util/sortChapters"
import { useProgress } from "../hooks/useProgress"
import { Dot } from "../components/progress-indicator/styles"
import ContinueLink from "../components/continue-link"

interface Data {
  allMdx: {
    nodes: Array<{
      id: string
      slug: string
      frontmatter: {
        index: number
        title: string
      }
      fields: {
        headings: Array<{
          id: string
          value: string
          depth: number
        }>
      }
    }>
  }
}

const TocPage: React.FC<PageProps<Data>> = ({ data }) => {
  const [progress] = useProgress()

  const chapters = useMemo(() => {
    return data.allMdx.nodes
      .filter(filterChapters)
      .sort(sortChapters)
      .map(node => ({
        id: node.id,
        title: getChapterNodeTitle(node),
        subtitle: node.frontmatter.title,
        link: `/${node.slug}`,
        children: node.fields.headings
          .filter(h => h.depth > 1)
          .map(h => ({ ...h, link: `/${node.slug}#${h.id}` })),
      }))
  }, [data.allMdx.nodes])

  return (
    <ThemeProvider theme={blueTheme}>
      <Seo title="Spis treści" />
      <GlobalStyles variant="blue" />
      <AdvancedHeader divider progress={<ContinueLink />} />
      <Styled.Layout>
        <PageHeading>Spis treści</PageHeading>
        <Styled.Spacer />
        <Styled.Content>
          <Styled.TableOfContents aria-label="Spis treści">
            {chapters.map(chapter => {
              const title = !chapter.subtitle ? (
                <Styled.ChapterLink to={chapter.link}>
                  {chapter.title}
                </Styled.ChapterLink>
              ) : (
                chapter.title
              )

              const subtitle = chapter.subtitle ? (
                <Styled.ChapterLink to={chapter.link}>
                  {chapter.subtitle}
                </Styled.ChapterLink>
              ) : undefined

              const childWithProgress = chapter.children.findIndex(
                c => c.id === progress.heading
              )

              return (
                <ListElement
                  key={chapter.id}
                  title={title}
                  subtitle={subtitle}
                  keyPrefix={chapter.id}
                  progress={
                    childWithProgress !== -1 || chapter.link === progress.link
                      ? progress.completion
                      : undefined
                  }
                >
                  {chapter.children.length > 0 && (
                    <ol aria-label="Podrozdziały">
                      {chapter.children.map((child, index) => (
                        <Styled.ChapterListElement
                          key={child.id}
                          data-depth={child.depth}
                        >
                          <Styled.ChapterLink to={child.link}>
                            {child.value}
                          </Styled.ChapterLink>
                          {index === childWithProgress ? (
                            <Dot $position="right" />
                          ) : null}
                        </Styled.ChapterListElement>
                      ))}
                    </ol>
                  )}
                </ListElement>
              )
            })}
          </Styled.TableOfContents>
          <ScrollUpCTA />
        </Styled.Content>
        <Divider
          mt={{ xs: "4rem", md: "7rem", lg: "9.5rem" }}
          mb={{ xs: "5rem", md: "7rem" }}
        />
      </Styled.Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        slug
        frontmatter {
          index
          title
        }
        fields {
          headings {
            value
            depth
            id
          }
        }
      }
    }
  }
`

export default TocPage
